<template>
  <div id="app">
	<notice ref="child"></notice>
    <router-view></router-view>
  </div>
</template>

<script>
import notice from './components/notice.vue'
export default {
  name: 'app',
	
	data() {
		return {
			
		}
	},
	components: {
		notice
	},
	metaInfo() {
		 return {
			
		 };
  },
  watch: {
			$route: {
			  handler: function(route) {
				
				if(localStorage.getItem('token')){
					this.$refs.child.$emit('childmothods');
					
				}else{
					this.show = true
				}
			  },
			  immediate: true
			}
		},

	
	created () {
	},
	mounted(){
			this.$refs.child.$emit('childmothods');
	},
	
	methods: {
		
	}
	 
	 
}
</script>
<style>
*,
*:after,
*:before {
    box-sizing: border-box;
}
*{
	font-family: PingFang SC;
}
.data-box {
	overflow-y: auto;
	/* padding-bottom: 50rem; */
}
.demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
    margin: 0;
    padding: 0
}
body {
	background-color: #0F1726!important;
	min-width: 1200rem;
}
body,
button,
input,
select,
textarea {
    font: 12px Arial, Helvetica, sans-serif
}
body {
	overflow: scroll!important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    color: #333;
    font-family: '微软雅黑', 'YaHei', '黑体', 'Hei', Tahoma, Helvetica, arial, sans-serif;
}

address,
cite,
dfn,
em,
var {
    font-style: normal
}

code,
kbd,
pre,
samp {
    font-family: courier new, courier, monospace
}

ul,
ol,
li {
    list-style: none
}

a {
    text-decoration: none;
    outline: none;
    color: inherit;
		color: #333!important;
}

a:hover {
    /* opacity: 0.7; */
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

sup {
    vertical-align: text-top
}

sub {
    vertical-align: text-bottom
}

legend {
    color: #000
}

fieldset,
img {
    border: 0
}

button,
input,
select,
textarea {
    font-size: 100%;
}

textarea {
    resize: none
}

button,
input {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    line-height: 1;
    box-sizing: border-box;
}

button:hover {
    cursor: pointer;
}

select {
    padding: 2rem;
    border: 1px solid #ddd;
    -moz-border-radius: 3rem;
    -khtml-border-radius: 3rem;
    -webkit-border-radius: 3rem;
    border-radius: 3px
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

img {
    vertical-align: top;
    display: inline-block;
    background-color: none;
		width: 100%;
		height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    display: block;
    margin: 0;
    padding: 0
}

object,
embed {
    outline: 0
}

.table-btn {
	/* width: 100rem; */
	height: 32rem;
	line-height: 30rem;
	border: 1px solid #48CBCC;
	background: linear-gradient(360deg, rgba(1, 169, 170, 0) 0%, rgba(72, 203, 204, 0.5) 100%);
	/* opacity: 1; */
	cursor: pointer;
}
	::-webkit-scrollbar {
	  width: 2rem;
	}
	::-webkit-scrollbar-thumb {
	  -webkit-box-shadow: inset 0 0 1px rgba(136, 136, 136, 0.3);
	  background-color: #48CBCC;
	}
</style>
