	import Vue from 'vue'
	export default { 
		showMsg (type, content, time, isclose) {
			if (type=='warning') {
				Vue.prototype.$Message.warning({
						content,
						duration: time,
						closable: isclose
				});
			}
			if (type=='error') {
				Vue.prototype.$Message.error({
						content,
						duration: time,
						closable: isclose
				});
			}
			if (type=='success') {
				Vue.prototype.$Message.success({
						content,
						duration: time,
						closable: isclose
				});
			}
		},
		
		spinShow () {
			Vue.prototype.$Spin.show({
					render: (h) => {
							return h('div', [
									h('Icon', {
											'class': 'demo-spin-icon-load',
											props: {
													type: 'ios-loading',
													size: 18
											}
									}),
									h('div', 'Loading')
							])
					}
			});
		},
		
		getDate () {
			var myDate = new Date();
			// myDate.toLocaleString( );        //获取日期与时间
			// myDate.getYear();        //获取当前年份(2位)
			// myDate.getFullYear();    //获取完整的年份(4位,1970-????)
			// myDate.getMonth();       //获取当前月份(0-11,0代表1月)
			// myDate.getDate();        //获取当前日(1-31)
			// myDate.getDay();         //获取当前星期X(0-6,0代表星期天)
			// myDate.getTime();        //获取当前时间(从1970.1.1开始的毫秒数)
			// myDate.getHours();       //获取当前小时数(0-23)
			// myDate.getMinutes();     //获取当前分钟数(0-59)
			// myDate.getSeconds();     //获取当前秒数(0-59)
			// myDate.getMilliseconds();    //获取当前毫秒数(0-999)
			// myDate.toLocaleDateString();     //获取当前日期
			// var mytime=myDate.toLocaleTimeString();     //获取当前时间
			var year = myDate.getFullYear()
			var mon = (myDate.getMonth()-0+1) > 9?(myDate.getMonth()-0+1):('0' + (myDate.getMonth()-0+1))
			var day = myDate.getDate() > 9?myDate.getDate(): '0' + myDate.getDate()
			var h = myDate.getHours() > 9?myDate.getHours(): '0' + myDate.getHours()
			var m = myDate.getMinutes() > 9?myDate.getMinutes(): '0' + myDate.getMinutes()
			var s = myDate.getSeconds() > 9?myDate.getSeconds(): '0' + myDate.getSeconds()
			return year + '-' + mon + '-' + day + ' ' + h + ':' + m + ':' + s
		}
	}