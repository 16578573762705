<template>
	<div class="contentnotice">
		<div class="scroll-wrap " style="cursor:pointer">
			<div class="fadeIn" @click="navigationTo">
				<Alert class="alert" type="warning" show-icon>{{ errors.errmsg }}</Alert>
			</div>
		</div>
		<div @click="bofang1" >
			<audio ref="audio" autoplay src="" controls  id="audio" loop style="position: absolute;left:-1000px;;"></audio>
		</div>
		
	</div>
</template>
<script>

export default {
	data() {
		return {
			errors: '',
			index: 0,
			intnum: undefined,
			fade: true,
			fadeIn: 'fadeIn',
			list: [],
			video:''
		};
	},
	created() {
		this.getNotice();
	},
	mounted(){
		this.$nextTick(function(){
			this.$on('childmothods',function(){
				this.$refs.audio.play();
			})
		})
	},
	methods: {
		// 获取故障信息
		async getNotice() {
			let _this = this;
			var res = await _this.post('basic/newFault');
			this.errors = res.list[0];
			
			this.video = res.warn;
			this.$refs.audio.src = this.video.warnMp3
			
			var time = setInterval(function() {
				if (_this.index == res.list.length - 1) {
					_this.errors = res.list[0];
					_this.index = 0;
				} else {
					_this.index = _this.index + 1;
					_this.errors = res.list[_this.index];
				}
				
			}, 5000);
		},
		bofang1(){
			
			this.$refs.audio.play();
		},
		// 分别跳转不同详情
		navigationTo() {
			// localStorage.setItem('device_title',device_title)
			let id = this.errors.id;
			if (this.$route.path == '/equip/list/detail2') {
				this.$router.replace({ path: '/equip/list/detail2', query: { id,is_view:1 } });
				this.bofang1()
				this.$router.go(0)
			} else {
				this.$router.replace({ path: '/equip/list/detail2', query: { id ,is_view:1} });
				this.bofang1()
			}
			
			// return
			// console.log(this.list, this.index);
			
		}
		//    //鼠标移入
		//     on_top_enter(){
		//         this.fade = false;
		//         // clearInterval(time)
		//         console.log('鼠标移入')

		//     },
		//     on_top_leave(){
		//         this.fade = true
		//         console.log('鼠标移出去')
		//     }
	}
};
</script>
<style scoped="scoped">
.alert {
	box-sizing: border-box;
	white-space: nowrap;
	min-width: 400px;
}
.contentnotice {
	position: fixed;
	top: 0px;
	left: 0;
	z-index: 9999;
}
.fadeIn {
	/* position: absolute; */
	top: 20%;
	left: 10px;
	width: 300px;
	padding: 0px 20px;
	border-radius: 30px;
	z-index: 9999;
	animation: fadeIn infinite 5s ease-in-out;
}

.fadeIn text {
	display: block;
	max-width: 80rpx;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 0.5;
	}

	50% {
		opacity: 1;
	}

	75% {
		opacity: 0.5;
		transform: translateY(-30rpx);
	}

	100% {
		opacity: 0;
		transform: translateY(-30rpx);
	}
}
</style>
