// 环境的切换
import axios from 'axios';
import Vue from 'vue'
if (process.env.NODE_ENV == 'development') {    
			axios.defaults.timeout = 60000;
			axios.interceptors.request.use(    
					config => {        
							var token =  localStorage.getItem('token')
							token && (config.headers.token = token)
							return config;    
					},    
					error => {        
							return Promise.error(error);    
			})
			
			var get =  function (url, params){    
					return new Promise((resolve, reject) =>{        
								axios.get("https://www.kochem.net.cn/wap/" + url, {            
										params: params        
								}).then(res => {
									console.log('resresresresres',res)
									if (res.data.code == 0) {
										Vue.prototype.$Message.error({
												content: res.data.msg,
												duration: 1.5,
												closable: false
										});
									} else if (res.data.code == -1) {
										this.$router.push({path: '/login'})
									} else {
										resolve(res.data.data);
									}
									  
								}).catch(err =>{
									resolve(err.response.data)        
						})    
				});
			}
			
			var post = function (url, params) {
					return new Promise((resolve, reject) => {
							 axios.post("https://www.kochem.net.cn/wap/"+url, params)
							.then(res => {
								if (res.data.code == 0) {
									Vue.prototype.$Message.error({
											content: res.data.msg,
											duration: 1.5,
											closable: false
									});
								} else if (res.data.code == -1) {
									this.$route.push({path: '/login'})
								} else {
									resolve(res.data.data);
								}
							})
							.catch(err =>{
								console.log(err)
									resolve(err.response.data)
							})
					});
			}
		
    } else if (process.env.NODE_ENV == 'production') {    
	
	axios.defaults.timeout = 60000;
	axios.interceptors.request.use(    
			config => {        
					var token =  localStorage.getItem('token')
					token && (config.headers.token = token)
					// config.headers['Content-Type'] = 'application/raw'
					return config;    
			},    
			error => {        
					return Promise.error(error);    
	})
	
	var get =  function (url, params){    
			return new Promise((resolve, reject) =>{        
						axios.get("https://www.kochem.net.cn/wap/" + url, {            
								params: params        
						}).then(res => {
								if (res.data.code == 0) {
									Vue.prototype.$Message.error({
											content: res.data.msg,
											duration: 1.5,
											closable: false
									});
								} else if (res.data.code == -1) {
									this.$router.push({path: '/login'})
								} else {
									resolve(res.data.data);
								}
						}).catch(err =>{
							resolve(err.response.data)        
				})    
		});
	}
	
	var post = function (url, params) {
			return new Promise((resolve, reject) => {
					 axios.post("https://www.kochem.net.cn/wap/"+url, params)
					.then(res => {
						// console.log(res)
						if (res.data.code == 0) {
							Vue.prototype.$Message.error({
									content: res.data.msg,
									duration: 1.5,
									closable: false
							});
						} else if (res.data.code == -1) {
							this.$route.push({path: '/login'})
						} else {
							resolve(res.data.data);
						}
					})
					.catch(err =>{
						console.log(err)
							resolve(err.response.data)
					})
			});
	}
   
}

export {
	get,
	post
}


