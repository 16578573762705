import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import(/* webpackChunkName:"goods"*/ '../components/home.vue')
const login = () => import(/* webpackChunkName:"goods"*/ '../components/login.vue')
// 设备一下路由
const equip = () => import(/* webpackChunkName:"goods"*/ '../components/equip.vue')
const equipMap = () => import(/* webpackChunkName:"goods"*/ '../components/equip_map.vue')
const equipMapyt = () => import(/* webpackChunkName:"goods"*/ '../components/equip_map_yt.vue')
const equipMapsj = () => import(/* webpackChunkName:"goods"*/ '../components/equip_map_sssj.vue')
const equipMapbj = () => import(/* webpackChunkName:"goods"*/ '../components/equip_map_bj.vue')
const equipMapeq = () => import(/* webpackChunkName:"goods"*/ '../components/equip_map_sb.vue')
const equipMaplist = () => import(/* webpackChunkName:"goods"*/ '../components/equip_maplist.vue')
const equipList = () => import(/* webpackChunkName:"goods"*/ '../components/equip_list.vue')
const equipListcom = () => import(/* webpackChunkName:"goods"*/ '../components/equip_listcom.vue')
const equipModel = () => import(/* webpackChunkName:"goods"*/ '../components/equip_model.vue')
const equipLog = () => import(/* webpackChunkName:"goods"*/ '../components/equip_log.vue')
const equipDetail = () => import(/* webpackChunkName:"goods"*/ '../components/equip_detail.vue')
const equipDetail1 = () => import(/* webpackChunkName:"goods"*/ '../components/equip_detail1.vue')
const equipDetail2 = () => import(/* webpackChunkName:"goods"*/ '../components/equip_detail2.vue')
const equipElectricMeter = () => import(/* webpackChunkName:"goods"*/ '../components/equip_electricmeter.vue')
const equipFault = () => import(/* webpackChunkName:"goods"*/ '../components/equip_fault.vue')
const curve = () => import(/* webpackChunkName:"goods"*/ '../components/curve.vue')


// 项目路由
const pro = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro.vue')
const proCate = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_cate.vue')
const proList = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_list.vue')
const proFollow = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_follow.vue')
const proZone = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_zone.vue')
const proSource = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_source.vue')
const proHabit = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_habit.vue')
const proSale = () => import(/* webpackChunkName:"goods"*/ '../views/project/pro_sale.vue')
// 用户管理
const user = () => import(/* webpackChunkName:"goods"*/ '../views/user/user.vue')
const userList = () => import(/* webpackChunkName:"goods"*/ '../views/user/user_list.vue')
const userZone = () => import(/* webpackChunkName:"goods"*/ '../views/user/user_zone.vue')
const userSource = () => import(/* webpackChunkName:"goods"*/ '../views/user/user_source.vue')
const userHabit = () => import(/* webpackChunkName:"goods"*/ '../views/user/user_habit.vue')
// 售后管理
const refund = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund.vue')
const refundPhone = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_phone')
const refundAdd = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_add')
const refundInstall = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_install')
const refundMaintain = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_maintain')
const refundOrder = () => import(/* webpackChunkName:"goods"*/ '../views/refund/order_detail')
const refundOrder1 = () => import(/* webpackChunkName:"goods"*/ '../views/refund/order_detail1')
const refundRecords = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_records')
const refundStaff = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_staff')
const refundTending = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_tending')
const refundWaste = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_waste')
const refundBadness = () => import(/* webpackChunkName:"goods"*/ '../views/refund/refund_badness')
// 站点管理
const site = () => import(/* webpackChunkName:"goods"*/ '../views/site/site.vue')
const siteBasic = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_basic.vue')
const siteRule = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_gz.vue')
const siteRole = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_role.vue')
const siteAdmin = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_admin.vue')
const sitesetAdmin = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_setadmin.vue')
const siteCase = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_case.vue')
const siteRoledetail = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_roledetail.vue')
const siteRoleadd = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_roleadd.vue')
const siteaddAdmin = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_addadmin')
const sitegzadd = () => import(/* webpackChunkName:"goods"*/ '../views/site/site_gzadd')


//新增测试大屏
const Home1 = () => import(/* webpackChunkName:"goods"*/ '../components/home1.vue')
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{path:'/',redirect:'/home'},
	// {path: '/',name: 'login',component: Home},
	{path:'/login',component: login},
    {path: '/home',name: 'home',component: Home},
	 {path: '/home1',name: 'home1',component: Home1},
	{path: '/equip', redirect: '/equip/map', component: equip,children: [
		{ path: '/equip/map', component:equipMap },
		{ path: '/equip/map/yt', component:equipMapyt },
		{ path: '/equip/map/sj', component:equipMapsj },
		{ path: '/equip/map/bj', component:equipMapbj },
		{ path: '/equip/map/eq', component:equipMapeq },
		{ path: '/equip/map/list', component:equipMaplist },
		{ path: '/equip/list', component:equipList },
		{ path: '/equip/list/common', component:equipListcom },
		{ path: '/equip/log', component:equipLog },
		{ path: '/equip/list/detail', component:equipDetail },
		{ path: '/equip/list/detail1', component:equipDetail1 },
		{ path: '/equip/list/detail2', component:equipDetail2 },
		{ path: '/equip/list/curve', component:curve },
		{ path: '/equip/electricmeter', component:equipElectricMeter },
		{ path: '/equip/model', component:equipModel },
		{ path: '/equip/fault', component:equipFault }
	]},
	{path: '/pro', redirect: '/pro/cate', component: pro,children: [
		{ path: '/pro/cate', component:proCate },
		{ path: '/pro/list', component:proList },
		{ path: '/pro/follow', component:proFollow },
		{ path: '/pro/zone', component:proZone },
		{ path: '/pro/source', component:proSource },
		{ path: '/pro/habit', component:proHabit },
		{ path: '/pro/sale', component:proSale },
	]},
	{path: '/user', redirect: '/user/list', component: user,children: [
		{ path: '/user/list', component:userList },
		{ path: '/user/zone', component:userZone },
		{ path: '/user/habit', component:userHabit },
		{ path: '/user/source', component:userSource },
	]},
	{path: '/refund', redirect: '/refund/phone', component: refund,children: [
		{ path: '/refund/phone', component:refundPhone },
		{ path: '/refund/add', component:refundAdd },
		{ path: '/refund/install', component:refundInstall },
		{ path: '/refund/maintain', component:refundMaintain },
		{ path: '/refund/maintain/order', component:refundOrder },
		{ path: '/refund/install/order', component:refundOrder1 },
		{ path: '/refund/records', component:refundRecords },
		{ path: '/refund/staff', component:refundStaff },
		{ path: '/refund/tending', component:refundTending },
		{ path: '/refund/waste', component:refundWaste },
		{ path: '/refund/badness', component:refundBadness },
	]},
	{path: '/site', redirect: '/site/basic', component: site,children: [
		{ path: '/site/basic', component:siteBasic },
		{ path: '/site/rule', component:siteRule,},
		{path: '/site/rule/add', component: sitegzadd},
		{ path: '/site/role', component:siteRole },
		{ path: '/site/admin', component:siteAdmin },
		{ path: '/site/admin/set', component:sitesetAdmin },
		{ path: '/site/case', component: siteCase},
		{ path: '/site/roledetail', component: siteRoledetail},
		{ path: '/site/roleadd', component: siteRoleadd},
		{ path: '/site/admin/add', component: siteaddAdmin},
	]}
]

// const router = () => new VueRouter({
//   base:'/screen/',
//   routes
 
// })
const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path == '/login') {
// 		next()
// 	} else {
// 		if (!localStorage.getItem('token')) {
// 			return next('/login')
// 		}
// 		next()
// 	}
// })

export default router
