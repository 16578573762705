import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入全局样式
import './assets/css/iconfont.css'
import './assets/css/common.css'

// 引入iView组件库
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
// 下面是省市区组件库
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'

// 粒子效果
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

// 导入echart
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 百度地图
import BaiduMap from 'vue-baidu-map'

// util里面的
import util from './util/utils.js'
Vue.prototype.util = util

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'iKCZNk8vPhZbcLwYx2nz8Bch5q5hKGiB'
})


Vue.config.productionTip = false


import { get,post } from './http'
Vue.prototype.get=get
Vue.prototype.post=post

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
